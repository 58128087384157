export enum FEATURE_FLAG {
  HUBS_FEATURE = 'hubs_feature',
  CARGAS_FEATURE = 'cargas_feature',
  PRICING_FEATURE = 'pricing_feature',
  VS_EXPORT_FEATURE = 'vs_export_feature',
  ROUTING_FEATURE = 'simple_shift_feature',
  DM2_EXPORT_FEATURE = 'dm2_export_feature',
  TANK_MONITORING = 'tank_monitoring_feature',
  PRICING_TYPE_SETTING = 'pricing_type_setting',
  SPLASH_BLEND_FEATURE = 'splash_blend_feature',
  TOWER_EXPORT_FEATURE = 'towerx_export_feature',
  LUBE_DELIVERY_FEATURE = 'lube_delivery_feature',
  TRANSFER_ORDER_FEATURE = 'transfer_order_feature',
  COMMON_CARRIER_FEATURE = 'common_carrier_feature',
  VENDOR_PRICING_FEATURE = 'vendor_pricing_feature',
  PERTROL_EXPORT_FEATURE = 'petrolrx_export_feature',
  RECURRING_ORDER_FEATURE = 'recurring_order_feature',
  EXTRACTION_ORDER_FEATURE = 'extraction_order_feature',
  DIVERSION_NUMBERS_FEATURE = 'diversion_numbers_feature',
  AM_PM_ORDER_GROUPING_FEATURE = 'am_pm_order_grouping_feature',
  IMPORT_RECURRING_ORDERS_FEATURE = 'import_recurring_orders_feature',
  ENABLE_2FA_AUTHENTICATION_FEATURE = 'enable_2fa_authentication_feature',
  MASTER_APPLY_APPLICABLE_TAX_FEATURE = 'master_apply_applicable_tax_feature',
  BULK_PLANT_SHIFT_AUTO_CREATE_FEATURE = 'bulk_plant_shift_auto_create_feature',
  AUTO_POPULATE_ORDER_FEATURE = 'populate_load_order_from_previous_shift_feature',
  BULK_DOWNLOAD_DELIVERY_RECEIPT_FEATURE = 'bulk_download_delivery_receipt_feature',
  LOADING_ACCOUNT_INSTEAD_SUPPLIER_FEATURE = 'loading_account_instead_supplier_feature',
}
