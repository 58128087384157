import { RecursiveObject } from '@chakra-ui/react';

export const fonts = {
  body: '"Montserrat", sans-serif',
  heading: '"Montserrat", sans-serif',
};

export const fontSizes: RecursiveObject = {
  '3xs': '0.5rem', //8px
  xxs: '0.5625rem', //9px
  xs: '0.625rem', //10px
  xsm: '0.6875rem', //11px
  sm: '0.75rem', //12px
  smd: '0.8125rem', //13px
  md: '0.875rem', //14px
  lg: '1rem', //16px
  xl: '1.125rem', //18px
  '2xl': '1.25rem', //20px
  '3xl': '1.5rem', //24px
  '4xl': '1.875rem', //30px
  '5xl': '2.25rem', //36px
  '6xl': '3rem', //48px
  '7xl': '3.75rem', //60px
  '8xl': '4.5rem', //72px
  '9xl': '6rem', //96px
};

export const lineHeights: RecursiveObject = {
  7.5: '1.875rem',
};
