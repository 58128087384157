import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { COMMONS, DATE_FORMAT } from 'globalConstants';
import { OperationInDateParamsType, DatesDiffType, FormatTimeStampType } from 'types/dateHelpers';
import { PandaRowDataType } from 'types/pandaTransferListWidget';
import { RootStateType } from 'types/store';

const { YYYY_MM_DD } = DATE_FORMAT;
const { ADD, SUBTRACT } = COMMONS;

const FALLBACK_TIMEZONE = 'America/Los_Angeles';

export const useDateHelpers = () => {
  const [currentSettings] = useSelector(
    (state: RootStateType) => [state.current.settings],
    shallowEqual
  );

  const DEFAULT_TIME_ZONE = useMemo(() => {
    const timeZoneFromStore = currentSettings?.find(
      (indvSetting: PandaRowDataType) => indvSetting?.name === 'timezone'
    )?.value;
    const checkTimeZone = DateTime.fromObject({}, { zone: timeZoneFromStore });
    return checkTimeZone.isValid ? timeZoneFromStore : FALLBACK_TIMEZONE;
  }, [currentSettings]);

  const getDatesDiff: DatesDiffType = (date1, date2, diffBy = 'days') => {
    const start =
      typeof date1 === 'string' ? DateTime.fromISO(date1) : DateTime.fromISO(date1.toISOString());
    const end =
      typeof date2 === 'string' ? DateTime.fromISO(date2) : DateTime.fromISO(date2.toISOString());
    const dateDiff = end.diff(start, diffBy);
    return dateDiff.toObject();
  };

  const operationInDate = (parmas: OperationInDateParamsType) => {
    const {
      dateTime,
      duration = 0,
      optBy = 'days',
      format = YYYY_MM_DD,
      optType = ADD,
      timeZone,
    } = parmas;
    if (!dateTime) {
      return '';
    }

    const tempDate =
      typeof dateTime === 'string'
        ? DateTime.fromISO(dateTime)
        : DateTime.fromISO(dateTime.toISOString());

    switch (optType) {
      case ADD:
        return timeZone
          ? tempDate
              .plus({ [optBy]: duration })
              .setZone(DEFAULT_TIME_ZONE)
              .toFormat(format)
          : tempDate.plus({ [optBy]: duration }).toFormat(format);
      case SUBTRACT:
        return timeZone
          ? tempDate
              .minus({ [optBy]: duration })
              .setZone(DEFAULT_TIME_ZONE)
              .toFormat(format)
          : tempDate.minus({ [optBy]: duration }).toFormat(format);
      default:
        return '';
    }
  };

  const getFormattedTimeStamp: FormatTimeStampType = (
    timeStamp,
    format = YYYY_MM_DD,
    timeZone = DEFAULT_TIME_ZONE
  ) => {
    if (!timeStamp) {
      return '';
    }
    if (timeZone) {
      return DateTime.fromSeconds(timeStamp).setZone(timeZone).toFormat(format);
    } else {
      return DateTime.fromSeconds(timeStamp).toFormat(format);
    }
  };

  const getDateTimeInUnix = (jsDateValue: DateTime) => {
    return DateTime.fromObject(
      {
        year: jsDateValue.year,
        month: jsDateValue.month,
        day: jsDateValue.day,
        hour: jsDateValue.hour,
        minute: jsDateValue.minute,
        second: jsDateValue.second,
      },
      { zone: DEFAULT_TIME_ZONE }
    ).toUnixInteger();
  };

  const getTodayDateTime = (format = YYYY_MM_DD, timeZone = DEFAULT_TIME_ZONE) => {
    if (timeZone) {
      return DateTime.now().setZone(timeZone).toFormat(format);
    } else {
      return DateTime.now().toFormat(format);
    }
  };

  const getTodayJSDate = (timeZone = DEFAULT_TIME_ZONE) => {
    if (timeZone) {
      return DateTime.now().setZone(timeZone)?.toJSDate();
    } else {
      return DateTime.now()?.toJSDate();
    }
  };

  return {
    DEFAULT_TIME_ZONE,
    getDatesDiff,
    getTodayJSDate,
    operationInDate,
    getTodayDateTime,
    getDateTimeInUnix,
    getFormattedTimeStamp,
  };
};
